import VOtpInput from "vue3-otp-input";
import TermsOfPurchase from '../../components/TermsOfPurchase/index.vue';
import { onMounted, ref, reactive, computed, watch, onBeforeMount } from "vue";
import { getOTPOnEmail, getOTP, verifyOTP, register } from "../../Actions/AuthActions";
import {
  fetchMarketplaces,
  fetchOrganisationTc,
} from "../../Actions/MarketPlaceActions";
import { addLeadingZero } from "../../misc/formatter";
import { userNameRegex, emailRegex } from "../../static";
import { useField, useForm } from "vee-validate";
import bluebirchLogo from "@/../assets/images/blubirch_logo.png";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { loaderStore, useAlertStore, useAuth } from "../../stores";
import configuration from "../../config.json";

export default {
  components: {
    VOtpInput,
    TermsOfPurchase
  },
  computed: {
    isFormValid() {
      return true
    },
    emailRules() {
      if (configuration.emailMandatory) {
        return [
          v => (typeof v === 'string' && v.trim() !== '') || 'Email is required',
          v => (typeof v === 'string' && /.+@.+\..+/.test(v.trim())) || 'Email must be valid',
          v => (typeof v === 'string' && v === v.trim()) || 'Email cannot have leading or trailing spaces',
        ]
      } else {
        return [
          v => (!v || (typeof v === 'string' && /.+@.+\..+/.test(v.trim()))) || 'Email must be valid',
          v => (!v || (typeof v === 'string' && v === v.trim())) || 'Email cannot have leading or trailing spaces',
        ]
      }
    }
  },
  images: {
    bluebirchLogo,
  },
  setup() {
    const authStore = useAuth();
    const alertStore = useAlertStore();
    const router = useRouter();
    const countDown = ref(0);
    const { registration_receiver } = configuration.gtag;
    const user = computed({
      get: () => authStore.user,
    });
    
    const sanitizeEmail = () => {
      if (email.value.value) {
        email.value.value = email.value.value.replace(/\s/g, '');
      }
    };

    const { handleSubmit, handleReset, validate, errors } = useForm({
      validationSchema: {
        email(value) {
          const isEmailMandatory = configuration.emailMandatory;
          if (value === undefined || value === null) {
            if (isEmailMandatory) {
              return "Email is required.";
            }
            return true;
          }
          if (typeof value !== 'string') {
            if (isEmailMandatory) {
              return "Email is required.";
            }
            return true;
          }
          const trimmedValue = value.replace(/^\s+|\s+$/g, '');
          if (isEmailMandatory && trimmedValue === '') {
            return "Email is required.";
          }
          if (!isEmailMandatory && trimmedValue === '') {
            return true;
          }
          const isValidFormat = /.+@.+\..+/.test(trimmedValue);
          const hasNoLeadingOrTrailingSpaces = value.match(/^\s|\s$/) === null;
          if (!isValidFormat) {
            return "Please enter a valid email address.";
          }
          if (!hasNoLeadingOrTrailingSpaces) {
            return "Email cannot have leading or trailing spaces.";
          }
          return true;
        },

        name(value) {
          const isValidName = !(/^\s/.test(value)) && /^[a-zA-Z ]*$/.test(value);
          if (value?.length >= 3 && isValidName) return true;
          return  !isValidName ? "Name should contain only letters & shouldn't start with whitespace." : "Please enter your full name.";
        },
        phone(value) {
          if (value?.length > 9 && /[0-9-]+/.test(value)) return true;

          return "Phone number needs to be at least 10 digits.";
        },
        password(value) {
          if (value?.length >= 8) return true;

          return "Please enter a valid password with minimum 8 characters long.";
        },
        organisationName(value) {
          if ((value?.length < 1) || !(/^\s/.test(value))) return true; 
          return "Please enter valid organisation name."
        }
      },
    });

    const name = useField("name");
    const email = useField("email");
    const organisationName = useField("organisationName");
    const phone = useField("phone");
    const password = useField("password");
    const select = useField("select");
    const selectedItems = ref(["Blubirch Liquidation Lots"]);
    const otpSent = ref(false);
    const marketPlaces = ref([]);
    const tcAccepted = ref(false);
    const otpVerified = ref(false);
    const emailMandat = configuration.emailMandatory;
    const otpFilled = ref(false);
    const otpValue = ref("");
    const items = ref([]);
    const tnc = ref(false);
    const tncModal = ref(false);
    const passwordVisible = ref(false);

    const { loading } = storeToRefs(loaderStore());

    const otpInput = ref(null);
    const bindModal = ref("");
    let interval;

    onMounted(() => {
      fetchMarketplaces().then((res) => {
        if (res.status === 200) {
          items.value = res.data?.external_service?.map((a) => {
            return { value: a.name, accept: false };
          });
          marketPlaces.value = res?.data?.external_service || [];
        }
      });
      interval = setInterval(() => {
        if (countDown.value > 0) {
          countDown.value = countDown.value - 1;
        }
      }, 1000);

    });

    const gtagEventTrigger = (eventType = "conversion") => {
      try {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = window.location.href;
          }
        };
    
        gtag('event', eventType, {
          'send_to': registration_receiver,
          'event_callback': callback
        });  
      } catch(err) {
        console.log(err)
      }
    }

    const submitOTP = () => {
      verifyOTP({
        otp_number: otpInput.value,
        mobile_number: phone.value._value,
      }).then((res) => {
        if (res.status === 200) {
          otpVerified.value = true;
        } else {
          alertStore.setAlertMessage("error", res.data?.message, true);
        }
      });
    };

    const tcClasses = () => {
      return tcAccepted.value ? "bg-green" : "bg-blue";
    };

    const getClasses = (value = "") => {
      const isAccepted =
        items.value.find((a) => a.value === value).accept || false;
      return {
        "bg-blue": !isAccepted,
        "bg-green": isAccepted,
      };
    };

    const sendEmailOtp = (e) => {
      e.preventDefault();
      let payload = { mobile_number: phone.value._value, email: ''};
      if(email.value._value && emailRegex.test(email.value._value)) {
        payload.email = email.value._value;
      }
      // if(!payload.email){
      //   alertStore.setAlertMessage("error", "Email is mandatory, Please enter your email address.");
      // }else {
        getOTPOnEmail(payload).then((res) => {
          if (res.status === 200) {
            otpSent.value = true;
            countDown.value = 59;
            alertStore.setAlertMessage("success", "An OTP code has been sent to your contact number and email.", true);
          } else {
            alertStore.setAlertMessage("error", res.data?.message, true);
          }
        });
      // }
    };

    const sendOtp = (e) => {
      e.preventDefault();
      getOTP({ mobile_number: phone.value._value }).then((res) => {
        if (res.status === 200) {
          otpSent.value = true;
          countDown.value = 59;
          alertStore.setAlertMessage("success", "An OTP code has been sent to your contact number.", true);
        } else {
          alertStore.setAlertMessage("error", res.data?.message, true);
        }
      });
    };

    const handleOnChange = (value) => {
      otpInput.value = value;
      otpFilled.value = value.length >= 6;
    };

    const clearInput = () => {
      otpInput.value?.clearInput();
    };

    const fillInput = (value) => {
      otpInput.value?.fillInput(value);
    };

    const itemData = reactive({
      dialog: false,
      activeMarketplace: "",
      tcAccepted: false,
      brandDialog: false,
      tcData: {},
    });

    const handleDialog = (value = "") => {
      const orgId = marketPlaces.value.find(
        (a) => a.name === value
      )?.id;
      itemData.activeMarketplace = value;
      if (orgId && orgId !== 'bb' && !itemData.tcData[value]) {
        fetchOrganisationTc(orgId.toString()).then((res) => {
          if (res.status === 200) {
            itemData.tcData[value] =
              res?.data?.terms_of_purchase.terms_and_conditions;
            itemData.dialog = true;
          } else {
            alertStore.setAlertMessage("error", res.data?.errors, true);
          }
        });
      } else {
        itemData.dialog = true;
      }
    };

    const handleAccept = (accept = false) => {
      tnc.value = accept
      itemData.dialog = false;
      itemData.brandDialog = false;
    };

    const handleChange = (e) => {
      if(e.target.checked) {
        tnc.value = false;
        itemData.dialog = true
      }
    } 

    const validateTc = () => {
      const isValid = tnc.value == true;
      const isVerified = otpVerified.value == true;

      const errMsg = !isValid ? "Please accept Terms and Condition." : "Please verify your mobile number."
      if (!isValid || !isVerified) {
        alertStore.setAlertMessage(
          "error",
          errMsg,
          true
        );
      }

      return (isValid && isVerified);
    };

    const getMarketPlaceImg = (marketPlace = "") => {
      return marketPlace === 'Blubirch Liquidation Lots' ? bluebirchLogo : marketPlaces.value.find((a) => a.name === marketPlace)
        ?.image_url;
    };

    const submit = handleSubmit((values) => {
      if (validateTc()) {
        const ids = marketPlaces.value.filter(obj => selectedItems.value.indexOf(obj.name) > -1).map(a => a.id);
        register({
          reseller_registration: {
            name: values.name,
            email: values.email,
            password_digest: values.password,
            mobile: values.phone,
            company_name: values.organisationName
            // organization_ids: ids
          },
        }).then((res) => {
          gtagEventTrigger()
          if (res.status === 200) {
            alertStore.setAlertMessage(
              "success",
              "Registered Successfully.",
              true
            );
            router.push({ name: "RegistrationSuccess" });
          } else {
            const errorObj = (res.data?.erorrs || res.data?.errors) ?? "";
            let errorMsg = typeof errorObj === 'object' ? Object.keys(errorObj).map(key => {
              return `${key} ${errorObj[key]}`
            }).join('. ') : errorObj;
            alertStore.setAlertMessage("error", errorMsg, true);
          }
        });
      }
    });

    const passwordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };
    

    const handleTnc = (e) => {
      e.preventDefault();
      itemData.dialog = true
    }
    // watch(itemData.dialog, (oldVal, newVal) => {
    //   if(oldVal !== newVal) {
    //     tncModal.value = !tncModal.value
    //   }
    // })

    return {
      name,
      email,
      phone,
      handleTnc,
      password,
      select,
      otpFilled,
      items,
      tnc,
      tncModal,
      getMarketPlaceImg,
      submit,
      sendEmailOtp,
      loading,
      organisationName,
      validate,
      handleReset,
      selectedItems,
      itemData,
      handleChange,
      sendOtp,
      getClasses,
      tcClasses,
      otpVerified,
      emailMandat,
      countDown,
      otpSent,
      handleOnChange,
      errors,
      fillInput,
      handleAccept,
      handleDialog,
      submitOTP,
      clearInput,
      addLeadingZero,
      passwordVisible,
      passwordVisibility,
      sanitizeEmail
    };
  },
};